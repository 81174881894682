import React from 'react';
import { Text } from '@/components/Text';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { ICharacter } from '@/models/characters';
import { default_avatar } from '@/assets/remote-assets';
import PlayIcon from '@/assets/icons/play-icon.svg';
import { cn } from '@/lib/utils';
import { SkeletonImage } from '@/components/SkeletonImage';
import { personalities, Personality } from '@/components/character-creation/v2/creationData';
import { relationships, Relationship } from '@/components/character-creation/v2/creationData';
import { getPersonalityAndRelationship } from '@/components/character-creation/util';
import posthog from 'posthog-js';

const PROBABILITY_OF_ROTATING_AVATARS = 0.2;

type Props = {
  character: ICharacter;
  className?: string;
  triggerAction: boolean;
  selected?: boolean;
  onCardClick?: (characterId: number) => void;
  nameOnly?: boolean;
  priority?: boolean;
};

export const CharacterCard: React.FC<Props> = ({ character, className, triggerAction, onCardClick, selected, nameOnly, priority }) => {
  const { push } = useRouter();
  const [avatarUrl, setAvatarUrl] = useState(character.avatar_urls && character.avatar_urls.length > 0 ? character.avatar_urls[0] : character.avatar_url || default_avatar);
  const [inRotation, setInRotation] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [personality, setPersonality] = useState<Personality | null>(null);
  const [relationship, setRelationship] = useState<Relationship | null>(null);

  useEffect(() => {
    if (!character.avatar_urls || character.avatar_urls.length <= 1) {
      setInRotation(true);
    } else {
      setInRotation(false);
    }
  }, []);

  useEffect(() => {
    if (character.created_by_user && character.description) {
      const { personality, relationship } = getPersonalityAndRelationship(character.description);
      setPersonality(personality);
      setRelationship(relationship);
    }
  }, [character]);

  useEffect(() => {
    if (!inRotation) {
      // Randomly decide if it's going to rotate the avatars
      const random = Math.random();
      if (random < PROBABILITY_OF_ROTATING_AVATARS) {
        rotateAvatars(true);
      }
    }
  }, [triggerAction]);

  function handleCardClick() {
    if (onCardClick) {
      onCardClick(character.id);
    } else {
      push(`/chat/${character.id}`);
      window.gtag && window.gtag('event', 'view_character_click', {character_id: character.id});
      posthog.capture('view_character_click', {character_id: character.id});
    }
  }

  const rotateAvatars = (anmating = false) => {
    // Change to a different avatar on hover
    if (character.avatar_urls) {
      // Find the first avatar that is not the same one as in current
      const currentAvatar = avatarUrl;
      const alternateAvatar = character.avatar_urls.find(url => url !== currentAvatar);

      if (anmating && alternateAvatar) {
        // Start the flip animation
        setIsAnimating(true);
        // Change to the alternate avatar in the middle or the animation
        setTimeout(() => {
          setAvatarUrl(alternateAvatar);
        }, 300);
        // Reset the animation state
        setTimeout(() => {
          setIsAnimating(false);
        }, 1000); // Duration of the animation
      } else {
        setAvatarUrl(alternateAvatar || currentAvatar);
      }
    }
  };

  const handleMouseEnter = () => {
    rotateAvatars();
    setInRotation(true);
  };

  const handleMouseLeave = () => {
    // Revert to the original avatar
    rotateAvatars();
    setInRotation(false);
  };

  return (
    <div
      className={cn('group relative rounded-xl cursor-pointer h-[400px] lg:h-[400px] xl:h-[400px]', className)}
      onClick={handleCardClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="absolute left-[3px] top-[3px] h-full w-full bg-black-100 rounded-[15px] z-0" />
      <div className={cn("flex relative h-full max-h-full max-w-full z-1 overflow-hidden rounded-[inherit] border-2 border-black", 
                        "group-hover:border-pink left-[0] top-[0] group-active:left-[1px] group-active:top-[1px] transition-all duration-200", 
                        selected ? 'border-pink' : '')}>
        {priority ? (
          <SkeletonImage
              className={cn(
              'z-0 absolute left-0 top-0 w-full h-auto min-h-[400px] pointer-events-none object-cover rounded-[10px]',
              isAnimating && 'flip-animation',
            )}
            height={0}
            width={0}
            sizes="(max-width: 768px) 50vw, 400px"
            src={avatarUrl}
            alt={character.name}
            priority={priority}
          />
        ) : (
          <SkeletonImage
              className={cn(
              'z-0 absolute left-0 top-0 w-full h-auto min-h-[400px] pointer-events-none object-cover rounded-[10px]',
              isAnimating && 'flip-animation',
            )}
            height={0}
            width={0}
            sizes="(max-width: 768px) 50vw, 400px"
            src={avatarUrl}
            alt={character.name}
            loading="lazy"
          />
        )}

        {character.voice_enabled && !nameOnly && (
          <PlayIcon className="w-6 h-6 absolute top-2 right-2 opacity-75" />
        )}
        <div className="absolute top-0 left-0 bg-card-gr w-full h-full" />
        <div className="w-full px-3 pb-3 z-10 relative flex flex-col flex-1 justify-end">
          <Text className="mb-0.5" variant="h3" textColor="white">
            {character.name}
          </Text>

          {!nameOnly ? (
            character.created_by_user ? (
              <div className="flex flex-wrap gap-2 max-w-[calc(100%-1rem)]">
                {personality && (
                  <div className="bg-base-100 rounded-lg px-3 py-2 flex items-center z-10 w-max">
                    <span className="mr-2 flex-shrink-0">{personality.asset}</span>
                    <Text variant="body-2" className="text-white whitespace-nowrap">{personality.name}</Text>
                  </div>
                )}
                {relationship && (
                  <div className="bg-base-100 rounded-lg px-3 py-2 flex items-center z-10 w-max">
                    <span className="mr-2 flex-shrink-0">{relationship.asset}</span>
                    <Text variant="body-2" className="text-white whitespace-nowrap">{relationship.name}</Text>
                  </div>
                )}
              </div>
            ) : (
              <Text
                variant="body-1"
                className="line-clamp-2 min-h-7 transition-all duration-300 text-white/[.8] group-hover:line-clamp-none"
              >
                {character.description}
              </Text>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
