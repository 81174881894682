import { MouseEventHandler } from 'react';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import { Button } from '@/components/buttons/Button';
import { openModal } from '@/components/BaseModal';
import { Modals } from '@/types/modals';
import posthog from 'posthog-js';
import { useAppStore } from '@/stores/app';

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const AnonymousBanner: React.FC<Props> = ({ className }) => {
  const { setSignInUiType, setIsSignInModalVisible } = useAppStore(s => s);

  function onClickSignIn() {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    window.gtag && window.gtag('event', 'signin_attempt', {'location': 'anonymous_banner'});
    posthog.capture('signin_attempt', {'location': 'anonymous_banner'});
  }

  return (
    <div className={cn('flex flex-col md:flex-row w-full bg-black-200 py-9 justify-center items-center', className)}>
      <Text className="mb-6 md:mb-0 text-center md:text-left text-h3 md:text-h3" textColor="white" variant="h3">
        Login to see more characters!
      </Text>
      <Button
        onClick={onClickSignIn}
        className="w-full md:w-auto ml-0 md:ml-8"
        wrapperClass="py-[12px] bg-blue-gr group-active/button:bg-white-gr"
      >
        <Text
          className="px-[27px] transition-all duration-150 text-white group-active/button:text-black"
          textColor={'white'}
          variant={'btn-1'}
        >
          Sign in
        </Text>
      </Button>
    </div>
  );
};
