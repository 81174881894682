import { AnonymousBanner } from '@/components/AnonymousBanner';
import { SidebarLayout } from '@/components/layout/SidebarLayout';
import { Text } from '@/components/Text';
import { CharactersList } from '@/components/page-components/home/CharactersList';
import { CharectersListSkeleton } from '@/components/page-components/home/CharectersListSkeleton';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useAppStore } from '@/stores/app';
import { BannersCarousel } from '@/components/page-components/home/BannersCarousel';
import { Footer } from '@/components/Footer';

export default function Home() {
  const { characters, loading } = useCharactersStore(s => ({
    characters: s.characters,
    loading: s.loading,
  }));
  const systemCharacters = characters.filter(c => c.created_by_user === false);
  const { banners, bannersLoading } = useAppStore(s => s);
  const isLoggedIn = useIsAuthenticated();

  return (
    <SidebarLayout>
      <div className="flex flex-col min-h-[calc(100dvh-60px)] justify-between">
          <div className={'mt-1'}>
            {banners && banners.length > 0 && (
              <BannersCarousel banners={banners} loading={bannersLoading} />
            )}
          </div>
        <div className="flex flex-col">
          <Text className="mt-8 mb-5 text-h5 md:text-h3" variant="h3" textColor="white" element="h1">
          ⚠️🔞 18+ NSFW Chat Scenarios 🔞⚠️
          </Text>
          <div className="min-h-[calc(100vh)]"> {/* Reserve space for characters list */}
            {systemCharacters && <CharactersList characters={systemCharacters} />}
            {!systemCharacters?.length && loading && <CharectersListSkeleton />}
          </div>
          {!isLoggedIn && !loading && <AnonymousBanner />}
        </div>

        <Footer />
      </div>
    </SidebarLayout>
  );
}
