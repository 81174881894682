import React from 'react';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores/app';

type Props = {
  cardsNumber?: number;
};

export const CharectersListSkeleton: React.FC<Props> = ({ cardsNumber = 10 }) => {
  return (
    <div className="grid h-fit w-full grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-[12px]">
      {Array.from(new Array(cardsNumber)).map((item, i) => (
        <div
          key={i}
          className={cn("skeleton bg-black-100 rounded-xl cursor-pointer h-[400px] lg:h-[400px] xl:h-[400px]")}
        ></div>
      ))}
    </div>
  );
};
