import React from 'react';
import { cn } from '@/lib/utils';
import { Banner as IBanner } from '@/models/banner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Banner } from './Banner';

type Props = {
  className?: string;
  loading: boolean;
  banners: IBanner[];
};

export const BannersCarousel: React.FC<Props> = ({ className, loading, banners }) => {
  const pagination = {
    clickable: true,
    dynamicBullets: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="w-4 h-4 border border-pink ' + className + '">' + '</span>';
    },
  };

  if (loading) {
    return <div className="skeleton relative flex w-full h-[200px] 2xl:h-[300px] p-8 pb-[19px]"></div>;
  }

  return (
    <Swiper
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      loop={false}
      spaceBetween={5}
      slidesPerView={1}
      pagination={pagination}
      modules={[Pagination, Autoplay]}
    >
      {!loading &&
        banners?.length > 0 &&
        banners.map((b, i) => (
          <SwiperSlide key={i}>
            <Banner banner={b} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
